import React from 'react'
import axios from 'axios'
import { getToken } from '../../utils/auth';
import './Confirmation.css'
const apiUrl = process.env.REACT_APP_API_URL;

const Confirmation = ({id, onClose, refresh, acceptNotify, rejectNotify}) => {
  const accept = async () => {
    try {
      const token = getToken()
      const response = await axios.put(
        `${apiUrl}/api/organization/update-status?id=${id}`,
        {
          isApproved: "active",
          reason: ""
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      onClose()
      acceptNotify()
      refresh(refresh)
    } catch (error) {
      console.error(error);
    }
  };

  const reject = async () => {
    onClose()
    rejectNotify(id)
  };
  return (
    <div className='confirmation-container'>
        <div className='sub-container' onClick={accept}>
            <img src="assest/correct_icon.svg" className='accept-icon'/>
            <p className='accept'>Accept</p>
        </div>
        <hr className='rejection-line'/>
        <div className='sub-container' onClick={reject}>
            <img src="assest/wrong_icon.svg"  className='reject-icon'/>
            <p className='reject'>Reject</p>
        </div>
    </div>
  )
}

export default Confirmation
  // Store token in localStorage
  export const setToken = (token) => {
    localStorage.setItem('token', token);
  };
  
  // Get token from localStorage
  export const getToken = () => {
    return localStorage.getItem('token');
  };
  
  // Clear token from localStorage
  export const clearToken = () => {
    localStorage.removeItem('token');
  };
  
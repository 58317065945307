import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = ({onLogOut}) => {
    const navigate = useNavigate()

    useEffect(() => {
        onLogOut(false)
        navigate('/')
    }, [])
    
  return (
    <div>Logout</div>
  )
}

export default Logout
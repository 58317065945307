// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import Login from "./Pages/Login/Login";
import Asidebar from "./Components/Asidebar/Asidebar";
// import NavBar from "./Components/NavBar/NavBar";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Support from "./Pages/Support/Support";
import Logout from './Pages/Logout/Logout';
import Donate from './Components/Donate/Donate';
import BankDetail from './Components/BankDetail/BankDetail';
import Loader from './Components/Loader/Loader';
import Submitted from './Components/Submitted/Submitted';

const App = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const userId = searchParams.get('userId');
  //http://localhost:3000/665edfe50f9a824e3f707019/660e37fad32f1c78412bf300

  const [isLoggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    if(id){
      navigate(`/donate/${id}/${userId}`);
    }
    const userLoggedIn = localStorage.getItem('isLoggedIn');
    if (userLoggedIn) {
      setLoggedIn(true);
    }
  }, []);

  const loginIn = (newState)=>{
    setLoggedIn(true);
    navigate('/dashboard');
    localStorage.setItem('isLoggedIn', 'true');
  }

  const logOut = (newState)=>{
    setLoggedIn(false);
    navigate('/');
    localStorage.removeItem('isLoggedIn');
  }
  
  return (
    <>
      {isLoggedIn && (
          <>
            {/* <Asidebar /> */}
            {/* <NavBar /> */}
          </>
        )}
       <Routes>
         <Route path="/" element={<Login onLogin={loginIn} />} />
         <Route path="/dashboard" element={<><Asidebar /><Dashboard/></>} />
         <Route path="/support" element={<><Asidebar /><Support/></>} />
         <Route path="/logout" element={<><Asidebar /><Logout onLogOut={logOut}/></>} />
         <Route path="/donate/:id/:userId" element={<Donate/>} />
         <Route path="/bank-detail" element={<BankDetail/>} />
         <Route path="/loader" element={<Loader/>} />
         <Route path="/submit" element={<Submitted/>} />
         <Route path="*" element={<Navigate to="/" />} />
       </Routes>
    </>
  )
}

export default App
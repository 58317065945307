// import React, { useEffect, useState } from 'react'
// import axios from 'axios';
// import FormData from 'form-data';

// import './BankDetail.css'
// import Loader from '../Loader/Loader';

// const BankDetail = ({ userId,amount,campaignId,organisationId}) => {
//     const [response, setResponse] = useState({});
//     const [name , setName] = useState('')
//     const [accNumber, setAccumber] = useState('')
//     const [ifscCode, setIfscCode] = useState('')
//     const [branch, setBranch] = useState('')
//     const [upiId, setUpiId] = useState('')
//     const [loader, setLoader ] =useState(true)
 
//     useEffect(() => {
//         const fetchCampaignData = async () => {
//             var config = {
//                 method: 'get',
//                 url: `https://masjidy.myofficejobs.com/api/bank-detail/get-bank-detail?organizationId=${organisationId}`,
//                 headers: { 
//                     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI'
//                 }
//             };
            
//             try {
//                 const response = await axios(config);
//                 console.log(response.data.data)
//                 if (response?.data?.data) {
//                     setResponse(response.data.data)
//                 }
//             } catch (error) {
//                 console.log(error);
//             } finally{
//                 setLoader(false)
//             }
//         };

//         fetchCampaignData();
//     }, [organisationId]);

//     useEffect(() => {
//         const fetchCampaignData = async () => {
//             var config = {
//                 method: 'get',
//                 url: `https://masjidy.myofficejobs.com/api/bank-detail/get-bank-detail?organizationId=${organisationId}`,
//                 headers: { 
//                     'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI'
//                 }
//             };
            
//             try {
//                 const response = await axios(config);
//                 console.log(response.data.data)
//                 if (response?.data?.data) {
//                     setResponse(response.data.data)
//                 }
//             } catch (error) {
//                 console.log(error);
//             }
//         };

//         fetchCampaignData();
//     }, [campaignId]);

//     const copyToClipboard = (text) => {
//         navigator.clipboard.writeText(text).then(() => {
//           //alert(`IFSC Code copied to clipboard! ${text}`);
//         }).catch(err => {
//           console.error('Failed to copy text: ', err);
//         });
//       }


//     useEffect(() => {
//         if(response.registeredName){
//             setName(response.registeredName)
//             setAccumber(response.accNumber)
//             setIfscCode(response.ifscCode)
//             setBranch(response.branch)
//             setUpiId(response.upiId)
//         }
       
//     }, [response])
    

//     const makeApiCall = async () => {
//         const data = new FormData();
        
//         data.append('campaignId', campaignId);
//         data.append('amount', amount);
//         data.append('organisationId', organisationId);
//         data.append('userId', userId);
//         data.append('transactionId', 'FCD1R234568');
//         data.append('upiId', upiId);
        
//         const config = {
//             method: 'post',
//             url: 'https://masjidy.myofficejobs.com/api/donar/donation',
//             headers: {
//                 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI'
//             },
//             data: data
//         };
    
//         try {
//             const response = await axios(config);
//             setResponse(response.data);
//             console.log(JSON.stringify(response.data));
//         } catch (error) {
//             console.error(error);
//         }
//     };
    
     
//     if(loader) {
//         return <Loader />;
//     }
    

//   return (
//     <div className='bank-detail-container'>
//         <div className='bank-detail-nav'>
//              Bank Detail
//         </div>
//         <div className='bank-detail-card'>
//             <div className='bank-detail-card-upper'>
//                 <div className='bank-detail-card-img'>
//                     {/* <img src='/assest/flying_money.svg' className='bank-detail-card-img'/> */}
//                 </div>
//                 <div className='bank-detail-card-detail'>
//                     <p className='bank-detail-card-header'>Donate to</p>
//                     <p className='bank-detail-card-subheader'>{name}</p>
//                 </div>
//             </div>
//             <hr className='bank-detail-line'/>
//             <div className='bank-detail-card-middle'>
//                 <div className='bank-detail-card-detail-mid'>
//                     <p className='bank-detail-card-subheader'>Name</p>
//                     <p className='bank-detail-card-header'>{name} <i className="fa-regular fa-copy"  onClick={() => copyToClipboard(name)}></i></p>
//                 </div>
//                 <div className='bank-detail-card-detail-mid'>
//                     <p className='bank-detail-card-subheader'>A/c Number</p>
//                     <p className='bank-detail-card-header'>{accNumber} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(accNumber)}></i></p>
//                 </div>
//                 <div className='bank-detail-card-detail-mid'>
//                     <p className='bank-detail-card-subheader'>IFSC Code</p>
//                     <p className='bank-detail-card-header'>{ifscCode} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(ifscCode)}></i></p>
//                 </div>
//                 <div className='bank-detail-card-detail-mid'>
//                     <p className='bank-detail-card-subheader'>Branch</p>
//                     <p className='bank-detail-card-header'>{branch} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(branch)}></i></p>
//                 </div>
//             </div>
//             <hr className='bank-detail-line'/>
//             <div className='bank-detail-card-lower'>
//                 <div className='bank-detail-card-detail'>
//                     <p className='bank-detail-card-subheader'>UPI ID</p>
//                     <p className='bank-detail-card-header'>{upiId} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(upiId)}></i></p>
//                 </div>
//             </div>
//         </div>
//         <div className='bank-detail-logo'>
//             <img src='/assest/logo.svg'></img>
//         </div>
//         <button className='pay-with-bank-button' onClick={makeApiCall}>Pay With Bank</button>
//         <button className='donate-button' onClick={makeApiCall}>Proceed to Pay</button>
//     </div>
//   )
// }

// export default BankDetail


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import FormData from 'form-data';
import './BankDetail.css';
import Loader from '../Loader/Loader';
import { useNavigate } from 'react-router-dom';

const BankDetail = ({ userId, amount, campaignId, organisationId }) => {
  const [response, setResponse] = useState({});
  const [name, setName] = useState('');
  const [accNumber, setAccNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');
  const [branch, setBranch] = useState('');
  const [upiId, setUpiId] = useState('');
  const [loader, setLoader] = useState(true);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchCampaignData = async () => {
      const config = {
        method: 'get',
        url: `https://masjidy.myofficejobs.com/api/bank-detail/get-bank-detail?organizationId=${organisationId}`,
        headers: {
          'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI`
        }
      };

      try {
        const response = await axios(config);
        if (response?.data?.data) {
          setResponse(response.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    fetchCampaignData();
  }, [organisationId]);

  useEffect(() => {
    if (response.registeredName) {
      setName(response.registeredName);
      setAccNumber(response.accNumber);
      setIfscCode(response.ifscCode);
      setBranch(response.branch);
      setUpiId(response.upiId);
    }
  }, [response]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };

  const makeApiCall = async () => {
    const data = new FormData();
    data.append('campaignId', campaignId);
    data.append('amount', amount);
    data.append('organisationId', organisationId);
    data.append('userId', userId);
    data.append('transactionId', 'FCD1R234568');
    data.append('upiId', upiId);

    const config = {
      method: 'post',
      url: 'https://masjidy.myofficejobs.com/api/donar/donation',
      headers: {
        'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI'
      },
      data: data
    };

    try {
      const response = await axios(config);
      setResponse(response.data);
      console.log(JSON.stringify(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageUpload = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const handleSubmit = () => {
    makeApiCall()
    navigate(`/submit`)
  };

  const openGooglePay = () => {
    makeApiCall()
    window.open('https://pay.google.com', '_blank');
  };

  if (loader) {
    return <Loader />;
  }

  return (
    <div className='bank-detail-container'>
      <div className='bank-detail-nav'>
        Bank Detail
      </div>
      <div className='bank-detail-card'>
        <div className='bank-detail-card-upper'>
          <div className='bank-detail-card-img'>
            {/* <img src='/assest/flying_money.svg' className='bank-detail-card-img'/> */}
          </div>
          <div className='bank-detail-card-detail'>
            <p className='bank-detail-card-header'>Donate to</p>
            <p className='bank-detail-card-subheader'>{name}</p>
          </div>
        </div>
        <hr className='bank-detail-line' />
        <div className='bank-detail-card-middle'>
          <div className='bank-detail-card-detail-mid'>
            <p className='bank-detail-card-subheader'>Name</p>
            <p className='bank-detail-card-header'>{name} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(name)}></i></p>
          </div>
          <div className='bank-detail-card-detail-mid'>
            <p className='bank-detail-card-subheader'>A/c Number</p>
            <p className='bank-detail-card-header'>{accNumber} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(accNumber)}></i></p>
          </div>
          <div className='bank-detail-card-detail-mid'>
            <p className='bank-detail-card-subheader'>IFSC Code</p>
            <p className='bank-detail-card-header'>{ifscCode} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(ifscCode)}></i></p>
          </div>
          <div className='bank-detail-card-detail-mid'>
            <p className='bank-detail-card-subheader'>Branch</p>
            <p className='bank-detail-card-header'>{branch} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(branch)}></i></p>
          </div>
        </div>
        <hr className='bank-detail-line' />
        <div className='bank-detail-card-lower'>
          <div className='bank-detail-card-detail'>
            <p className='bank-detail-card-subheader'>UPI ID</p>
            <p className='bank-detail-card-header'>{upiId} <i className="fa-regular fa-copy" onClick={() => copyToClipboard(upiId)}></i></p>
          </div>
        </div>
      </div>
      <div className='bank-detail-logo'>
        <img src='/assest/logo.svg'></img>
       {showImageUploader && <input type='file' onChange={handleImageUpload} />}
      </div>
     {!showImageUploader && <button className='pay-with-bank-button' onClick={() => setShowImageUploader(true)}>Pay With Bank</button>}
      {showImageUploader && (
        <div className='image-uploader'>
          <button className='pay-with-bank-button'  onClick={handleSubmit}>Submit</button>
        </div>
      )}
      <button className='donate-button' onClick={openGooglePay}>Proceed to Pay</button>
    </div>
  );
};

export default BankDetail;

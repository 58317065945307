import React from 'react'
import './Card.css'

const Card = ({icon,header,count}) => {
  return (
    <div className="card">
        <div className="title">
        <div className="icon">
            <img src={`assest/${icon}.svg`} />
        </div>
        <div className="header">
            {header}
        </div>
        </div>
        <div className="value">
            <div className="count">
            {count}
            </div>
            {/* <div className="prog-container">
            <img src="assest/grow_icon.svg" /> 89.65
            </div> */}
        </div>
    </div>
  )
}

export default Card
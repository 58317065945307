import React from 'react'
import './Progress.css'

const Progress = ({per}) => {
  return (
            <div className="meter" style={{"width": "80vw"}}>
                <span style={{"width": `${per >= 100 ? 100: per}%`}}></span>
            </div>
        )
}

export default Progress
import React from 'react'
import './PopUpAccepted.css'

const PopUpAccepted = ({onClose}) => {
  return (<>
            <div className='pop'>
              <div className='pop-up-container'>
                  <img src="assest/cancel_icon.svg" className='cancel' onClick={onClose}/>
                  <img src="assest/pop_up_accepted.svg"/>
                  <p className='notify'>Request Accepted</p>
              </div>
            </div>
        </>
   
  )
}

export default PopUpAccepted
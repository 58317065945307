import React, { useState } from 'react'
import './Ticket.css'
import axios from 'axios';
import { getToken } from '../../utils/auth';
const apiUrl = process.env.REACT_APP_API_URL;

const Ticket = ({id,userId,ticketId,name,contact,discription,status,timestamp,refreshTickets}) => {

    const [refresh,setRefresh] = useState(false)

    const closeTicket=async()=>{
        const token = getToken()
            try {
              const response = await axios.post(`${apiUrl}/api/ticket/close-ticket?id=${id}`, {
                headers: {
                  'Authorization': `Bearer ${token}`
                },
              });
               refreshTickets();
            } catch (error) {
              console.log(error)
            }
    }
  return (
    <div className='ticket-container'>
        <div className='ticket-details'>
            <p className='ticket-number'>Ticket ID: {ticketId}</p>
            <p className='ticket-date'>{timestamp}</p>
        </div>
        <div className='ticket-of'>
            {name} ({contact})
        </div>
        <div className='ticket-issue'>
            {discription}
        </div>
        <div className='ticket-action'>
           {!status ? <button className='ticket-open'>
                Open
            </button>:
            <button className='ticket-close'>
                Close
            </button>}
           {!status && <button className='ticket-close-button' onClick={closeTicket}>
                Mark Close
            </button>}
        </div>
       
    </div>
  )
}

export default Ticket
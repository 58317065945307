import React, { useState } from 'react'
import './Asidebar.css'
import { NavLink } from 'react-router-dom';


const Asidebar = () => {
  const [close, setClose] = useState(false)

  const collapse =()=>{
    setClose(!close)
  }

  return (
   <>
    <div className='asidebar-container' style={{ display: close ? 'none' : 'block' }}>
      <img src="assest/logo.svg" className='asidebar-logo'/>
      <p className='asidebar-header'>Main Menu</p>
      <NavLink to="/dashboard">
        <div className='tab-container'>
            <img src="assest/dashboard_icon.svg" className='asidebar-icon'/>
            <p className='asidebar-label'>Dashboard</p>
        </div>
      </NavLink>
      <NavLink to="/support">
        <div className='tab-container'>
            <img src="assest/support_icon.svg" className='asidebar-icon'/>
            <p className='asidebar-label'>Support</p>
        </div>
      </NavLink>
      <NavLink to="/logout">
        <div className='tab-container'>
            <img src="assest/dashboard_icon.svg" className='asidebar-icon'/>
            <p className='asidebar-label'>Logout</p>
        </div>
      </NavLink>
  </div>
   <div className='asidebar-click'>
      <img src="assest/hamburg.svg" className='asidebar-hamburger' onClick={collapse}/>
   </div>
   
   </>
   
  )
}

export default Asidebar
import React, { useState } from 'react'
import axios from 'axios'
import { getToken } from '../../utils/auth';
import './RejectionReason.css'
const apiUrl = process.env.REACT_APP_API_URL;

const RejectionReason = ({id,onClose,refresh}) => {
  const [reason, setReason] = useState('');

  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  const handleSubmit = async() => {
    const token = getToken()
    try {
        const response = await axios.put(
          `${apiUrl}/api/organization/update-status?id=${id}`,
          {
            isApproved: "reject",
            reason: reason
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }
        );
        onClose()
        refresh(refresh)
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='pop'>
      <div className='rejection-reason-container'>
            <img src="assest/cancel_icon.svg" className='cancel' onClick={onClose}/>
                <p className='rejection-label'>
                    Write reason for rejection
                </p>
                <textarea className='rejection-field' onChange={handleReasonChange}></textarea>
                <div className='button-div'>
                    <button className='rejection-button' onClick={handleSubmit}>
                        Submit
                    </button>
                </div>
        </div>
    </div>
    
  )
}

export default RejectionReason
import React, { useEffect, useState } from 'react'
import Progress from '../Progress/Progress'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './Donate.css'
import BankDetail from '../BankDetail/BankDetail';
import Loader from '../Loader/Loader';

const Donate = () => {
    const { id, userId } = useParams();
    const [amountTillNow, setAmountTillNow] = useState(0);
    const [amountToRaise, setAmountToRaise] = useState(0);
    const [amountRecPer, setAmountRecPer] = useState(0);
    const [amount,setAmount] = useState(0)
    const [organisationId, setOrganisationId] = useState(0)
    const [upiId, upiIdSet] = useState()
    const [result, setResult] =useState({})
    const [pay, setPay] = useState(false)
    const [loader, setLoader ] =useState(true)
       
    useEffect(() => {
        const fetchCampaignData = async () => {
            var config = {
                method: 'get',
                url: `https://masjidy.myofficejobs.com/api/campaign/get-campaign?id=${id}`,
                headers: { 
                    'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImJhZGFsQGdtYWlsLmNvbSIsImNvbnRhY3QiOiI3NjY2MDg5OTE5IiwibmFtZSI6ImJhZGFsIiwiaXNBZG1pbiI6ZmFsc2UsImtleSI6IkFJemFTeUItdDVsSE5ESDR6elNiTmlrT0RiTkNBdmlRSzBYUXRsRSIsImlhdCI6MTcxNzQ5MzQzM30._pa6XoNmukpiJ6GUvoOQP8eoO2067T0-ok9uKUu1MnI'
                }
            };
            
            try {
                const response = await axios(config);
                console.log(response.data.data)
                if (response?.data?.data) {
                    setResult(response.data.data)
                }
            } catch (error) {
                console.log(error);
            } finally{
                setLoader(false)
            }
        };

        fetchCampaignData();
    }, [id]);

    useEffect(() => {
        if(result.amountToRaise){
            setAmountTillNow(result.amountTillNow);
            setAmountToRaise(result.amountToRaise);
            setOrganisationId(result.organizationId);
            upiIdSet()
        }
    }, [result]);

    useEffect(() => {
        if (amountToRaise > 0) {
            setAmountRecPer((amountTillNow / amountToRaise) * 100);
        }
    }, [amountTillNow,amountToRaise])
    
    const handleInputChange = (e) => {
        const value = e.target.value;
        setAmount(parseInt(value, 10) || 0); // Parse the value as an integer or set it to 0 if the parse fails
    };

    const decrement = () => {
        setAmount((prevAmount) => Math.max(prevAmount - 1, 0)); // Prevent negative amounts
    };
    
    const increment = () => {
        setAmount((prevAmount) => prevAmount + 1);
    };
    
    if(loader) {
        return <Loader />;
    }
    
    
  return (
     <>
      {!pay? 
        <div className='donate-container'>
            <div className='donate-nav'>
                Donate
            </div>
            <div className='donate-header'>
                Donation Goal
            </div>
            <div className='donate-card'>
                <div className='donate-card-upper'>
                    <div className='donate-card-col'>
                        <p className='donate-card-header'>Raised</p>
                        <p className='donate-card-value'>₹{amountToRaise}</p>
                    </div>
                    <div className='donate-card-col'>
                        <p className='donate-card-header'>Pledged</p>
                        <p className='donate-card-value'>₹{amountTillNow}</p>
                    </div>
                </div>
                <div className='donate-card-lower'>
                    <Progress per={amountRecPer}/>   <p className='donate-card-header'>{amountRecPer >= 100 ? 100 :amountRecPer}%</p>
                </div>
            </div>
            <div className='donate-money'>
                <div className='donate-img-container'>
                    <img src='/assest/flying_money.svg' ></img>
                </div>
                <div className='donate-counter'>
                    <p className='donate-counter-minus' onClick={decrement}>-</p>
                    <input 
                        name="quantity" 
                        type="text" 
                        className="donate-text" 
                        value={amount}
                        onChange={handleInputChange}
                    />
                    <p className='donate-counter-plus' onClick={increment}>+</p>
                </div>
                <div className='donate-option-container'>
                    <div className='donate-option' onClick={()=>setAmount(100)}>
                        &#x20b9; 100
                    </div>
                    <div className='donate-option' onClick={()=>setAmount(500)}>
                        &#x20b9; 500
                    </div>
                    <div className='donate-option' onClick={()=>setAmount(1000)}>
                        &#x20b9; 1,000
                    </div>
                    <div className='donate-option' onClick={()=>setAmount(5000)}>
                        &#x20b9; 5,000
                    </div>
                </div>
                <div className='donate-dis'>
                    Choose the amount you wish to donate. You can also manually type amount.
                </div>
            </div>
            <button className='donate-button' onClick={()=>{amountTillNow <= amountToRaise && amount && setPay(true)}}>Proceed to Pay</button>
        </div>
        :
        <BankDetail 
         userId={userId} 
         amount={amount} 
         campaignId={id} 
         organisationId={organisationId}
         />
      }

     </>
       
  )
}

export default Donate